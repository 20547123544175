import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import * as echarts from 'echarts';
import {addDateRange, parseTime, resetForm} from "@/utils/ruoyi";
import plugins from './plugins' // plugins
import {download} from '@/utils/request'


import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import '@/assets/css/element-variables.scss'
import '@/assets/icons/index.js'
import '@/utils/globalVarService.js'
// 分页组件
import Pagination from "@/components/Pagination";
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"


Vue.config.productionTip = false

Vue.use(ElementUI, {size: 'small'})
Vue.prototype.$echarts = echarts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.download = download


Vue.component('Pagination', Pagination)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)

Vue.use(plugins)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
