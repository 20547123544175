import request from '@/utils/request'

// 登录方法
export function login(data) {
    return request({
        url: '/login',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
        method: 'post',
        data: data
    })
}

// 注册方法
export function register(data) {
    return request({
        url: '/register',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/getInfo',
        method: 'get'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/logout2',
        method: 'get'
    })
}

// 获取验证码
export function getCodeImg(key) {
    return request({
        url: '/captcha?key=' + key,
        headers: {
            isToken: false,
            ContentType: 'image/jpeg'
        },
        method: 'get',
        timeout: 20000
    })
}
