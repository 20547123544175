import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/login', name: 'login', component: () => import('@/views/LoginView.vue'),
}, {
    path: '/register', name: 'register', component: () => import('@/views/RegisterView.vue'),
},

    {
    path: '/',
    name: 'home',
    meta: {title: '首页'},
    redirect: '/web/system',
    component: () => import('@/views/LayoutView.vue'),
    children: [
        {
            path: '/updatePwd', name: 'updatePwd', component: () => import('@/views/system/user/resetPwd.vue'),
        },
        {
        path: 'web', name: 'web', component: () => import('@/views/main/MainView.vue'), children: [{
            path: 'system',
            name: 'system',
            meta: {title: '中国皮划艇协会竞赛管理系统'},
            component: () => import('@/views/main/0-home/System.vue'),
        }, {
            path: 'coaches',
            name: 'coaches',
            meta: {title: '教练员'},
            component: () => import('@/views/main/MainView.vue'),
            children: [{
                path: 'mass-youth',
                name: 'coaches/mass-youth',
                meta: {title: '大众青少年皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'coaches/mass-youth/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/system/user/index.vue')
                    // component: () => import('@/views/main/1-coaches/mass-youth/CoachMYRegister.vue')
                }, {
                    path: 'train',
                    name: 'coaches/mass-youth/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/system/train/index.vue')
                    // component: () => import('@/views/main/1-coaches/mass-youth/CoachMYTrain.vue')
                }]
            }, {
                path: 'open-water',
                name: 'coaches/open-water',
                meta: {title: '大众皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'coaches/open-water/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/system/user/index2.vue')
                    // component: () => import('@/views/main/1-coaches/open-water/CoachOWRegister.vue')
                }, {
                    path: 'train',
                    name: 'coaches/open-water/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/system/train/index2.vue')
                    // component: () => import('@/views/main/1-coaches/open-water/CoachOWTrain.vue')
                }]
            },]
        }, {
            path: 'referees',
            name: 'referees',
            meta: {title: '裁判员'},
            component: () => import('@/views/main/MainView.vue'),
            children: [{
                path: 'mass-youth',
                name: 'referees/mass-youth',
                meta: {title: '大众青少年皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'referees/mass-youth/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }, {
                    path: 'train',
                    name: 'referees/mass-youth/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }, {
                    path: 'dispatch',
                    name: 'referees/mass-youth/dispatch',
                    meta: {title: '比赛派遣'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }]
            }, {
                path: 'open-water',
                name: 'referees/open-water',
                meta: {title: '大众皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'referees/open-water/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }, {
                    path: 'train',
                    name: 'referees/open-water/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }, {
                    path: 'dispatch',
                    name: 'referees/open-water/dispatch',
                    meta: {title: '比赛派遣'},
                    component: () => import('@/views/main/0-home/Empty.vue')
                }]
            },]
        }, {
            path: 'athletes',
            name: 'athletes',
            meta: {title: '运动员'},
            component: () => import('@/views/main/MainView.vue'),
            children: [{
                path: 'mass-youth',
                name: 'athletes/mass-youth',
                meta: {title: '大众青少年皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'athletes/mass-youth/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/system/user/index3.vue')

                    // component: () => import('@/views/main/0-home/Empty.vue')
                }, {
                    path: 'train',
                    name: 'athletes/mass-youth/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/system/train/index3.vue')
                    // component: () => import('@/views/main/1-coaches/open-water/CoachOWTrain.vue')
                }]
            }, {
                path: 'open-water',
                name: 'athletes/open-water',
                meta: {title: '大众皮划艇静水'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'register',
                    name: 'athletes/open-water/register',
                    meta: {title: '年度注册'},
                    component: () => import('@/views/system/user/index4.vue')
                }, {
                    path: 'train',
                    name: 'athletes/open-water/train',
                    meta: {title: '培训信息与报名'},
                    component: () => import('@/views/system/train/index4.vue')
                }]
            },]
        }, {
            path: 'competition',
            name: 'competition',
            meta: {title: '赛事信息'},
            component: () => import('@/views/main/MainView.vue'),
            children: [
                {
                    path: 'calendar',
                    name: 'competition/calendar',
                    meta: {title: '年度赛事日历公告'},
                    component: () => import('@/views/system/race/index.vue')
                },
                {
                    path: 'sign_up',
                    name: 'competition/sign_up',
                    meta: {title: '赛事信息与报名'},
                    component: () => import('@/views/main/MainView.vue'),
                    children: [
                        {
                            path: 'mass_youth',
                            name: 'competition/sign_up/mass_youth',
                            meta: {title: '大众青少年皮划艇静水'},
                            component: () => import('@/views/system/race/index2.vue')
                        }, {
                            path: 'open_water',
                            name: 'competition/sign_up/open_water',
                            meta: {title: '大众皮划艇静水'},
                            component: () => import('@/views/system/race/index3.vue')
                        }]

                },]
        }, {
            path: 'results',
            name: 'results',
            meta: {title: '竞赛成绩公告'},
            component: () => import('@/views/main/MainView.vue'),
            children: [{
                path: 'mass-youth',
                name: 'results/mass-youth',
                meta: {title: '大众青少年皮划艇静水'},
                component: () => import('@/views/system/race/index4.vue')
            }, {
                path: 'open-water',
                name: 'results/open-water',
                meta: {title: '大众皮划艇静水'},
                component: () => import('@/views/system/race/index5.vue')
            },]
        },
            {
                path: 'points',
                name: 'points',
                meta: {title: '积分赛信息'},
                component: () => import('@/views/main/MainView.vue'),
                children: [
                    {
                        path: 'mass_youth',
                        name: 'points/mass_youth',
                        meta: {title: '大众青少年皮划艇静水'},
                        component: () => import('@/views/main/MainView.vue'),
                        children: [
                            {
                                path: 'ranking',
                                name: 'points/mass_youth/ranking',
                                meta: {title: '积分排行榜'},
                                component: () => import('@/views/main/MainView.vue'),
                                children: [{
                                    path: 'one',
                                    name: 'points/mass_youth/ranking/one',
                                    meta: {title: '单人积分排名榜'},
                                    component: () => import('@/views/system/rank/index.vue')
                                }, {
                                    path: 'two',
                                    name: 'points/mass_youth/ranking/two',
                                    meta: {title: '双人积分排名榜'},
                                    component: () => import('@/views/system/rank/index2.vue')
                                }, {
                                    path: 'four',
                                    name: 'points/mass-youth/ranking/four',
                                    meta: {title: '四人积分排名榜'},
                                    component: () => import('@/views/system/rank/index3.vue')
                                }, {
                                    path: 'year',
                                    name: 'points/mass-youth/ranking/year',
                                    meta: {title: '年度综合积分排名榜'},
                                    component: () => import('@/views/system/rank/index4.vue')
                                }]
                            },
                            {
                                path: 'athletes-info',
                                name: 'points/mass_youth/athletes-info',
                                meta: {title: '运动员信息'},
                                component: () => import('@/views/system/integral/index.vue')
                                // component: () => import('@/views/main/6-points/mass-youth/MYAthleteInfo.vue'),

                            },
                            {
                                path: "statistic",
                                name: "points/mass_youth/statistic",
                                meta: {title: '运动员参赛人数统计（年）'},
                                component: () => import('@/views/main/MainView.vue'),
                                children: [
                                    {
                                        path: "retention",
                                        name: "points/mass_youth/statistic/retention",
                                        meta: {title: '留榜运动员相关信息'},
                                        component: () => import('@/views/main/MainView.vue'),
                                        children: [{
                                            path: 'name-list',
                                            name: 'points/mass_youth/statistic/retention/name-list',
                                            meta: {title: '留榜运动员名单'},
                                            component: () => import('@/views/system/retention/index.vue')
                                        }, {
                                            path: 'number',
                                            name: 'points/mass_youth/statistic/retention/number',
                                            meta: {title: '留榜人数统计'},
                                            component: () => import('@/views/system/retention/statisticsNum.vue')
                                        }, {
                                            path: 'rate',
                                            name: 'points/mass_youth/statistic/retention/rate',
                                            meta: {title: '留榜率'},
                                            component: () => import('@/views/system/retention/statisticsRate.vue')
                                        }]
                                    },
                                    {
                                        path: "entry",
                                        name: "points/mass_youth/statistic/entry",
                                        meta: {title: '新入榜运动员相关信息'},
                                        component: () => import('@/views/main/MainView.vue'),
                                        children: [{
                                            path: 'name-list',
                                            name: 'points/mass_youth/statistic/entry/name-list',
                                            meta: {title: '新入榜运动员名单'},
                                            component: () => import('@/views/system/retention2/index.vue')
                                        }, {
                                            path: 'number',
                                            name: 'points/mass_youth/statistic/entry/number',
                                            meta: {title: '新入榜人数统计'},
                                            component: () => import('@/views/system/retention2/statisticsNum.vue')
                                        }, {
                                            path: 'rate',
                                            name: 'points/mass_youth/statistic/entry/rate',
                                            meta: {title: '新入榜率'},
                                            component: () => import('@/views/system/retention2/statisticsRate.vue')
                                        }]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'open_water',
                        name: 'points/open_water',
                        meta: {title: '大众皮划艇静水'},
                        component: () => import('@/views/main/MainView.vue'),
                        children: [
                            {
                                path: 'ranking',
                                name: 'points/open_water/ranking',
                                meta: {title: '积分排行榜'},
                                component: () => import('@/views/main/MainView.vue'),
                                children: [{
                                    path: 'one',
                                    name: 'points/open_water/ranking/one',
                                    meta: {title: '单人积分排名榜'},
                                    component: () => import('@/views/system/rank2/index.vue')
                                }, {
                                    path: 'two',
                                    name: 'points/open_water/ranking/two',
                                    meta: {title: '双人积分排名榜'},
                                    component: () => import('@/views/system/rank2/index2.vue')
                                }, {
                                    path: 'four',
                                    name: 'points/mass-youth/ranking/four',
                                    meta: {title: '四人积分排名榜'},
                                    component: () => import('@/views/system/rank2/index3.vue')
                                }, {
                                    path: 'year',
                                    name: 'points/mass-youth/ranking/year',
                                    meta: {title: '年度综合积分排名榜'},
                                    component: () => import('@/views/system/rank2/index4.vue')
                                }]
                            },
                            {
                                path: 'athletes-info',
                                name: 'points/open_water/athletes-info',
                                meta: {title: '运动员信息'},
                                component: () => import('@/views/system/integral/index2.vue')
                            },
                            {
                                path: "statistic",
                                name: "points/open_water/statistic",
                                meta: {title: '运动员参赛人数统计（年）'},
                                component: () => import('@/views/main/MainView.vue'),
                                children: [
                                    {
                                        path: "retention",
                                        name: "points/open_water/statistic/retention",
                                        meta: {title: '留榜运动员相关信息'},
                                        component: () => import('@/views/main/MainView.vue'),
                                        children: [{
                                            path: 'name-list',
                                            name: 'points/open_water/statistic/retention/name-list',
                                            meta: {title: '留榜运动员名单'},
                                            component: () => import('@/views/system/retention/index2.vue')
                                        }, {
                                            path: 'number',
                                            name: 'points/open_water/statistic/retention/number',
                                            meta: {title: '留榜人数统计'},
                                            component: () => import('@/views/system/retention/statisticsNum2.vue')
                                        }, {
                                            path: 'rate',
                                            name: 'points/open_water/statistic/retention/rate',
                                            meta: {title: '留榜率'},
                                            component: () => import('@/views/system/retention/statisticsRate2.vue')
                                        }]
                                    },
                                    {
                                        path: "entry",
                                        name: "points/open_water/statistic/entry",
                                        meta: {title: '新入榜运动员相关信息'},
                                        component: () => import('@/views/main/MainView.vue'),
                                        children: [{
                                            path: 'name-list',
                                            name: 'points/open_water/statistic/entry/name-list',
                                            meta: {title: '新入榜运动员名单'},
                                            component: () => import('@/views/system/retention2/index2.vue')
                                        }, {
                                            path: 'number',
                                            name: 'points/open_water/statistic/entry/number',
                                            meta: {title: '新入榜人数统计'},
                                            component: () => import('@/views/system/retention2/statisticsNum2.vue')
                                        }, {
                                            path: 'rate',
                                            name: 'points/open_water/statistic/entry/rate',
                                            meta: {title: '新入榜率'},
                                            component: () => import('@/views/system/retention2/statisticsRate2.vue')
                                        }]
                                    }
                                ]
                            }
                        ]
                    },

                ]
            },
            {
                path: 'announcements',
                name: 'announcements',
                meta: {title: '中国皮划艇协会管理相关公告'},
                component: () => import('@/views/main/MainView.vue'),
                children: [{
                    path: 'coach',
                    name: 'announcements/coach',
                    meta: {title: '教练员相关公告'},
                    component: () => import('@/views/system/notice/index.vue'),
                }, {
                    path: 'athlete',
                    name: 'announcements/athlete',
                    meta: {title: '运动员相关公告'},
                    component: () => import('@/views/system/notice/index2.vue'),
                }, {
                    path: 'policy',
                    name: 'announcements/policy',
                    meta: {title: '管理制度与政策相关公告'},
                    component: () => import('@/views/system/notice/index3.vue'),
                }, {
                    path: 'register',
                    name: 'announcements/register',
                    meta: {title: '注册大众皮划艇静水教练员信息公告'},
                    component: () => import('@/views/system/notice/index4.vue'),
                }]
            }, {
                path: 'empty',
                name: 'empty',
                meta: {title: '功能暂未开发'},
                component: () => import('@/views/main/0-home/Empty.vue'),
            }, {
                path: '403',
                name: '403',
                meta: {title: '403'},
                component: () => import('@/views/main/8-error/Error403.vue'),
            }, {
                path: '404',
                name: '404',
                meta: {title: '404'},
                component: () => import('@/views/main/8-error/Error404.vue'),
            },],
    },],
}]

const router = new VueRouter({
    mode: 'history', base: process.env.BASE_URL, routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
